import React, { useEffect, useRef, useState } from 'react'
import styles from './Roulette.module.scss'
import '../../../assets/style/global.scss'
import axiosDB from '../../../utils/axios.js'
import { formatTimeUntil } from '../../../utils/formatDate.js'
import Button from '../../UI/Button/Button.jsx'
import Loader from '../../UI/Loader/Loader.jsx'
import PrizeModal from './PrizeModal/PrizeModal.jsx'
const Roulette = () => {
	const [prize, setPrize] = useState(null)
	const [showPrize, setShowPrize] = useState(false)
	const [isDisabled, setIsDisabled] = useState(true)
	const [date, setDate] = useState(null)
	const popupRef = useRef(null)
	const [isLoading, setIsLoading] = useState(true)
	const [isSpin, setIsSpin] = useState(false)
	const [openPrizes, setOpenPrizes] = useState(false)
	useEffect(() => {
		if (window.Telegram?.WebApp) {
			const tg = window.Telegram.WebApp
			tg.ready()
			tg.expand()
		} else {
			console.error('Telegram WebApp is not available.')
		}

		const handleLoad = () => {
			setIsLoading(false)
		}

		if (document.readyState === 'complete') {
			setIsLoading(false)
		} else {
			window.addEventListener('load', handleLoad)
		}

		return () => {
			window.removeEventListener('load', handleLoad)
		}
	}, [])

	useEffect(() => {
		if (window.Telegram?.WebApp) {
			const tg = window.Telegram.WebApp;
			tg.ready();
			tg.expand();
		} else {
			console.error('Telegram WebApp is not available.');
		}

		const handleLoad = () => {
			setIsLoading(false);
		};

		if (document.readyState === 'complete') {
			setIsLoading(false);
		} else {
			window.addEventListener('load', handleLoad);
		}

		return () => {
			window.removeEventListener('load', handleLoad);
		};
	}, [])
	useEffect(() => {
		const oldBodyStyles = {
			backgroundImage: document.body.style.backgroundImage,
			backgroundSize: document.body.style.backgroundSize,
			backgroundPosition: document.body.style.backgroundPosition,
			backgroundRepeat: document.body.style.backgroundRepeat,
			backgroundColor: document.body.style.backgroundColor,
			transform: document.body.style.transform,
			overflow: document.body.style.overflow
		};

		const oldHtmlStyles = {
			height: document.documentElement.style.height,
			width: document.documentElement.style.width
		};

		document.body.style.backgroundImage = `url(assets/images/background.svg)})`;
		document.body.style.backgroundSize = 'contain';
		document.body.style.backgroundPosition = 'center';
		document.body.style.backgroundRepeat = 'no-repeat';
		document.body.style.backgroundColor = '#ffcbdb';
		document.body.style.transform = 'scale(100%)';
		document.body.style.overflow = 'hidden';

		document.documentElement.style.height = '100vh';
		document.documentElement.style.width = '100vw';

		return () => {
			document.body.style.backgroundImage = oldBodyStyles.backgroundImage;
			document.body.style.backgroundSize = oldBodyStyles.backgroundSize;
			document.body.style.backgroundPosition = oldBodyStyles.backgroundPosition;
			document.body.style.backgroundRepeat = oldBodyStyles.backgroundRepeat;
			document.body.style.backgroundColor = oldBodyStyles.backgroundColor;
			document.body.style.transform = oldBodyStyles.transform;
			document.body.style.overflow = oldBodyStyles.overflow;

			document.documentElement.style.height = oldHtmlStyles.height;
			document.documentElement.style.width = oldHtmlStyles.width;
		};
	}, []);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id
				if (userId) {
					const response = await axiosDB.get(`/spin/${userId}`)
					if (response.status === 200) {
						setIsDisabled(false)
					} else {
						setIsDisabled(true)
						setDate(formatTimeUntil(response.data.date))
					}
				} else {
					console.error('User ID not found in Telegram WebApp')
				}
			} catch (error) {
				if (error.response?.data?.date) {
					setDate(formatTimeUntil(error.response.data.date))
				}
				setIsDisabled(true)
				console.error('Error fetching data:', error)
			}
		}

		fetchData()
	}, [])

	useEffect(() => {
		window.onSpinComplete = prizeText => {
			setIsSpin(false)
			setPrize(prizeText)
			setShowPrize(true)
		}

		return () => {
			window.onSpinComplete = null
		}
	}, [])

	useEffect(() => {
		const handleClickOutside = event => {
			if (popupRef.current && !popupRef.current.contains(event.target)) {
				setShowPrize(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		document.addEventListener('touchstart', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			document.removeEventListener('touchstart', handleClickOutside)
		}
	}, [])

	const handleClick = event => {
		if (window.spinWheel) {
			setIsSpin(true)
			window.spinWheel(event)
		}
	}

	const handlePrizeClick = () => {
		window.location.href = 'https://t.me/dr_PushkinaV'
	}

	if (isLoading) {
		return <Loader />
	}

	const openPrizeModal = () => {
		setOpenPrizes(true)
	}

	const closePrizeModal = () => {
		setOpenPrizes(false)
	}

	return (
		<div className={styles.container}>
			<div className={styles.actions}>
				<Button
					className={isDisabled || isSpin ? 'disabled' : ''}
					text={isDisabled ? date : 'Крутить'}
					onClick={handleClick}
				/>
				<button className={styles.openPrizes} onClick={openPrizeModal}>
					Все призы ▼
				</button>
			</div>
			<PrizeModal isOpen={openPrizes} onClose={closePrizeModal} />
			{showPrize && (
				<div className={styles.prizePopup} ref={popupRef}>
					<h2>Поздравляем!</h2>
					<div className='text'>
						<p>Вы выиграли:</p>
						<p className='prize'>
							{prize.prizeText} <s>{prize.prevPrice}</s>
						</p>
					</div>
					<button className={styles.prizeButton} onClick={handlePrizeClick}>
						Получить
					</button>
				</div>
			)}
			<div className={styles.canvas} id='canvas' onClick={handleClick}></div>
		</div>
	)
}

export default Roulette
